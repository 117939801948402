<template>
	<div>
		<v-card class="pa-5">
			<v-row>
				<v-col cols="12" sm="10">
					<h1 class="display-1 mb-4">
						{{ customer.lastName.toUpperCase() }} {{ customer.firstName }}

						<v-tooltip right :color="$cssGlobal.getStatus($listUtils.elementStatus[customer.status])">
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-bind="attrs" v-on="on" :color="$cssGlobal.getStatus($listUtils.elementStatus[customer.status])" fab dark height="15px" width="15px" class="mb-3"></v-btn>
							</template>
							<span>{{ $listUtils.elementStatus[customer.status] }}</span>
						</v-tooltip>
					</h1>

					<p class="subtitle-2">
						<span>
							<v-icon small>mdi-alpha-c-circle-outline</v-icon> Client n°{{ customer.nClient }}
						</span>
						<span v-if="customer.creationDate">
							・ <v-icon small v-if="customer.creationDate">mdi-clock-outline</v-icon> Ajouté à Katana le {{ $functions.getDateFormat(customer.creationDate, "DD/MM/YYYY") }}
						</span>
						<span v-if="customer.birthdate">
							・ <v-icon small>mdi-cake</v-icon> Anniversaire {{ $functions.getDateFormat(customer.birthdate, "DD/MM/YYYY") }}
						</span>
					</p>

					<v-row v-if="customer.phone || customer.email || customer.website">
						<v-col cols="12" class="pb-0">
							<h3 class="font-weight-light">Contact</h3>
						</v-col>
						<v-col v-if="customer.phone">
							<p>Téléphone : {{ customer.phone }}</p>
						</v-col>
						<v-col v-if="customer.email">
							<p>Email : {{ customer.email }}</p>
						</v-col>
						<v-col v-if="customer.website">
							<p>Site internet : <a :href="customer.website" target="_blank">{{ customer.website }}</a></p>
						</v-col>
					</v-row>

					<v-row v-if="customer.address.street">
						<v-col cols="12" class="pb-0">
							<h3 class="font-weight-light">Adresse</h3>
						</v-col>
						<v-col>
							<p>
								{{ customer.address.street }}<br />
								{{ customer.address.region }}
							</p>
						</v-col>
						<v-col>
							<p>
								{{ customer.address.postalCode }} {{ customer.address.city }}<br />
								{{ customer.address.country }}
							</p>
						</v-col>
					</v-row>

					<v-row v-if="customer.job">
						<v-col cols="12" class="pb-0">
							<h3 class="font-weight-light">Détails</h3>
						</v-col>
						<v-col v-if="customer.job">
							<p>Métier : {{ customer.job }}</p>
						</v-col>
					</v-row>
				</v-col>

				<v-col cols="12" sm="2">
					<v-img v-if="customer.avatar" contain :src="$functions.getImgLink(customer.avatar)"></v-img>
				</v-col>

				<v-col v-if="customer.bio">
					<h3 class="font-weight-light pb-3">Description</h3>
					<p>{{ customer.bio }}</p>
				</v-col>

				<v-col cols="auto">
					<v-btn outlined small class="mt-3" @click="dialogs.modify = true">Modifier</v-btn>
				</v-col>
			</v-row>
		</v-card>

		<v-card class="pa-5 mt-5" v-if="admin">

			<v-row>

				<v-col cols="12" sm="3">
					<v-col cols="12" class="pb-0">
						<h3 class="font-weight-light">Identifiant</h3>
					</v-col>
					<v-col>
						<p>
							{{ customer.id }}
						</p>
					</v-col>
				</v-col>

				<v-col cols="12" sm="3">
					<v-col cols="12" class="pb-0">
						<h3 class="font-weight-light">Accès beta</h3>
					</v-col>
					<v-col>
						<p>
							{{ customer.beta ? "Oui" : "Non" }}
						</p>
					</v-col>
				</v-col>

			</v-row>

		</v-card>

		<v-dialog v-model="dialogs.modify" max-width="950px">
			<v-card>
				<v-card-title>
					<span class="headline">Modifier {{ customer.lastName.toUpperCase() }} {{ customer.firstName }}</span>
				</v-card-title>
				<v-card-text>
					<v-container>

						<v-form ref="form">

							<v-row>
								<v-col cols="12" sm="6">
									<span class="headline mt-5 mb-1">Profil</span>
									<v-row>
										<v-col cols="12" sm="8" class="pb-0">
											<v-select v-model="customer.civility" :items="this.$listUtils.civility" :rules="this.$fieldsRules.required('civilté', true)" label="Civilité" required></v-select>
										</v-col>
										<v-col cols="12" sm="4" class="pb-0">
											<v-text-field disabled label="Code Client" hint="Code du contact" v-model="customer.nClient"></v-text-field>
										</v-col>
									</v-row>

									<v-row>
										<v-col cols="12" sm="6" class="pt-0">
											<v-text-field label="Nom" hint="Nom du contact" v-model="customer.lastName" :rules="this.$fieldsRules.required('nom')"></v-text-field>
										</v-col>
										<v-col cols="12" sm="6" class="pt-0">
											<v-text-field label="Prénom" hint="Prénom du contact" v-model="customer.firstName" :rules="this.$fieldsRules.required('prénom')"></v-text-field>
										</v-col>
									</v-row>

									<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field v-model="formatDate" label="Date de naissance" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
										</template>
										<v-date-picker first-day-of-week="1" v-model="birthdate" @input="menu = false"></v-date-picker>
									</v-menu>
								</v-col>

								<v-col cols="12" sm="6">
									<span class="headline mt-5 mb-1">Contact</span>

									<v-text-field label="N° de Téléphone" hint="N° de téléphone du contact" v-model="customer.phone"></v-text-field>
									<v-text-field label="Adresse Mail" hint="Adresse mail du contact" v-model="customer.email" :rules="this.$fieldsRules.email"></v-text-field>

									<v-text-field prefix="https://" label="Site Web" hint="Lien du site web du contact" v-model="customer.website"></v-text-field>
								</v-col>

								<v-col cols="12" sm="6">
									<ImageUpload v-if="company" :companyID="company" type="customer" label="Avatar du contact" @newdata="customer.avatar=$event" />
								</v-col>

								<v-col cols="12" sm="6">
									<v-img v-if="customer.avatar" contain max-height="150" :src="$functions.getImgLink(customer.avatar)"></v-img>
								</v-col>
							</v-row>

						</v-form>

					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="modifyCustomer()">
						Modifier
					</v-btn>
					<v-btn color="blue darken-1" text @click="dialogs.modify = false">
						Fermer
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>


<script>
	import ImageUpload from '@/components/graphics/ImageUpload';
	
	export default {
		props: {
			'customer': {
				type: Object
			},
			'company': {
				type: String
			},
			'isUser': {
				type: Boolean,
				default: false
			},
			'admin': {
				type: Boolean,
				default: false
			}
		},
		components: {
			ImageUpload
		},
		data() {
			return {
				date: null,
				menu: false,

				dialogs: {
					modify: false
				},

				birthdate: null
			}
		},
		computed: {
			formatDate() {
				return this.$moment(this.birthdate).format('DD/MM/YYYY');
			}
		},
		watch: {
			customer: {
				deep: true,
				handler() {
					if(!this.birthdate && this.customer.birthdate) this.birthdate = this.$functions.getDateFormat(this.customer.birthdate, 'YYYY-MM-DD');
				}
			}
		},
		methods: {
			modifyCustomer() {
				const newData = {
					...this.customer,
					birthdate: new Date(this.birthdate)
				};

				delete newData.id;
				delete newData.creationDate;
				delete newData.company;

				const ref = this.isUser ? this.$db.collection("users") : this.$db.collection("companies").doc(this.company).collection("customers");

				ref.doc(this.customer.id).set(newData, { merge: true }).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: `Le client ${this.customer.firstName} ${this.customer.lastName[0].toUpperCase()}. a été modifié.`
					});

					this.dialogs.modify = false;
				});
			}
		}
	}
</script>