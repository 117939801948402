<template>
	<div class="FicheEntreprise pa-5">
		<Head title="Utilisateur Katana" v-if="customer" :subTitle="customer.lastName.toUpperCase() + ' ' + customer.firstName" offset="15vh" height="50vh" />


		<v-row class="mt-3">

			<v-col cols="12" sm="8">
				<InfoCustomer v-if="customer" :customer="customer" large admin isUser />

				<v-card>
					<v-col cols="12">

						<span class="headline">Entreprises liées</span>

						<v-col cols="12">
							<v-data-table :headers="headers" :items="companies" :item-key="companies.id" single-expand :expanded.sync="expanded" show-expand>

								<template v-slot:[`item.avatar`]="{ item }">

									<v-row align="center" justify="center">
										<v-img v-bind:src="$functions.getImgLink(item.avatar, 50)" :lazy-src="$functions.getImgLink(item.avatar, 50)" aspect-ratio="1" max-width="50" max-height="50">
											<template v-slot:placeholder>
												<v-row class="fill-height ma-0" align="center" justify="center">
													<v-progress-circular indeterminate :size="50" :width="1" color="primary"></v-progress-circular>
												</v-row>
											</template>
										</v-img>
									</v-row>

								</template>

								<template v-slot:[`item.rank`]="{ item }">
									<v-btn :disabled="item.position >= $listUtils.rankInCompany.length-1" @click="$katana.statusRole(customer.id, item.id, item.position+1), item.position=item.position+1" icon>
										<v-icon>mdi-chevron-down</v-icon>
									</v-btn>
									{{ $listUtils.rankInCompany[item.position] }}
									<v-btn :disabled="item.position <= 1" @click="$katana.statusRole(customer.id, item.id, item.position-1), item.position=item.position-1" icon>
										<v-icon>mdi-chevron-up</v-icon>
									</v-btn>
								</template>

								<template v-slot:[`item.manage`]="{ item }">
									<v-btn text icon :to="{path: '/administration/company/' + item.id}">
										<v-icon>mdi-arrow-right-bottom</v-icon>
									</v-btn>
								</template>

								<template v-slot:expanded-item="{ headers, item }">

									<td :colspan="headers.length">
										<div class="row sp-details">
											<div class="col-12 text-left">
												<v-img id="blurMe" height="200px" v-bind:src="$functions.getImgLink(item.avatar, 200)" style="margin-bottom: -150px;">
												</v-img>
											</div>


											<div class="col-12 text-left">
												<v-card-title>{{ item.name }}</v-card-title>
												<v-card-subtitle>{{ item.phone }} • {{ item.email }}
												</v-card-subtitle>
												<v-card-subtitle v-if="item.address.street">
													{{ item.address.street }}
													<br />
													{{ item.address.city }} • {{ item.address.postalCode }}
													<br />
													{{ item.address.region }} @ {{ item.address.country }}
												</v-card-subtitle>

												<v-card-text class="text--primary">
													<div>Identifiant unique : {{ item.id }}</div>
												</v-card-text>
											</div>

										</div>
									</td>

								</template>

							</v-data-table>
						</v-col>

					</v-col>
				</v-card>

				<v-card class="mt-3">
					<v-col cols="12">
						<span class="headline">Informations bancaire</span>

						<v-row class="mt-3">
							<v-col cols="8" v-if="customer.tva.status">
								<h3 class="font-weight-regular">TVA</h3>
								<v-text-field label="Numéros TVA" hint="Votre numéro de TVA Intracommunautaire" v-model="customer.tva.number"></v-text-field>
							</v-col>

							<v-col cols="12">
								<v-row>
									<v-col cols="4">
										<v-text-field disabled label="Code Pays" hint="Entrer le nom de votre banque" v-model="customer.bank_info.country"></v-text-field>
									</v-col>
									<v-col cols="8">
										<v-text-field disabled label="Banque" hint="Entrer le nom de votre banque" v-model="customer.bank_info.bank"></v-text-field>
									</v-col>
								</v-row>

								<v-text-field disabled label="IBAN / BIC" v-model="customer.bank_info.iban"></v-text-field>
							</v-col>
						</v-row>
					</v-col>
				</v-card>
				
			</v-col>

			<v-col cols="12" sm="4">
				<v-card>
					<v-col cols="12">
						<span class="headline">Gestion</span>

						<v-row class="mt-2 mx-4">
							<v-col cols="6">
								<v-btn text block color="warning" @click="resetPassword()">Réinitialiser le mot de passe</v-btn>
							</v-col>
							<!--<v-col cols="6">
								<v-btn text block color="error" @click="dialog.suspend = true">Suspendre</v-btn>
							</v-col>-->
							<v-col cols="6">
								<v-btn text block color="primary" @click="dialog.rgpd = true">Gestion RGPD</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-card>

				<v-card class="mt-3">
					<v-col cols="12">
						<span class="headline">Mode de communication préféré</span>

						<v-row class="mt-2">
							<v-col cols="4">
								<v-switch v-model="customer.contact.mail" flat label="Mail" disabled></v-switch>
							</v-col>
							<v-col cols="4">
								<v-switch v-model="customer.contact.call" flat label="Appel" disabled></v-switch>
							</v-col>
							<v-col cols="4">
								<v-switch v-model="customer.contact.sms" flat label="SMS" disabled></v-switch>
							</v-col>

						</v-row>

						<v-col cols="12" class="px-0">
							<v-text-field v-if="customer.contact.preference" label="Remarque" disabled v-model="customer.contact.preference"></v-text-field>
							<span v-else>Ce client n'a pas fourni de remarque concernant la communication.</span>
						</v-col>
					</v-col>
				</v-card>

				<v-card class="mt-3">
					<v-col cols="12">
						<span class="headline">Notifications</span>
						<v-row>

							<v-col cols="12" sm="6">


								<v-col cols="12">
									<span>Demande de synchronisation</span>
								</v-col>

								<v-row>
									<v-col cols="4">
										<v-switch v-model="customer.notification.sync.mail" flat disabled label="Mail"></v-switch>
									</v-col>
									<v-col cols="4">
										<v-switch v-model="customer.notification.sync.app" flat disabled label="InApp">
										</v-switch>
									</v-col>
								</v-row>
							</v-col>

							<v-col cols="12" sm="6">

								<v-col cols="12">
									<span>Réception d'un devis/facture</span>
								</v-col>

								<v-row>
									<v-col cols="4">
										<v-switch v-model="customer.notification.quote.mail" flat disabled label="Mail"></v-switch>
									</v-col>
									<v-col cols="4">
										<v-switch v-model="customer.notification.quote.app" flat disabled label="InApp"></v-switch>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-col>
				</v-card>
			</v-col>

		</v-row>

		<v-dialog v-model="dialog.rgpd" max-width="50%">
			<v-card>
				<v-card-title class="headline">
					RGPD
				</v-card-title>

				<v-card-text>
					<p>Données concernées : profil, données créées par l'utilisateur.</p>

					<v-row class="my-4">

						<v-col cols="6">
							<v-btn text @click="makeABackup()">Obtenir une copie des données</v-btn>
						</v-col>

						<v-col cols="6">
							<p>La copie des données sera téléchargée sous un fichier JSON.</p>
						</v-col>
						
					</v-row>
					
					<v-row class="my-4">

						<v-col cols="6">
							<v-btn text disabled>Envoyer par mail une copie des données</v-btn>
						</v-col>

						<v-col cols="6">
							<p>Le fichier de données sera envoyé à {{ customer.email }}.</p>
						</v-col>

					</v-row>

					<v-row class="my-4">

						<v-col cols="6">
							<v-btn text disabled color="error">Supprimer toutes les données</v-btn>
						</v-col>

						<v-col cols="6">
							<p>Toutes les données seront supprimées définitivement.</p>
						</v-col>

					</v-row>

				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="dialog.rgpd = false">
						Fermer
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialog.suspend" max-width="50%">
			<v-card>
				<v-card-title class="headline">
					Voulez-vous vraiment suspendre {{ customer.firstName }} {{ customer.lastName.toUpperCase() }} ?
				</v-card-title>

				<v-card-text>

					<p>Si vous validez cette action, cet utilisateur perdra ses accès Katana et ne pourra plus se connecter.</p>

				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" text @click="suspendUser()">
						Oui, suspendre
					</v-btn>
					<v-btn color="primary" text @click="dialog.suspend = false">
						Annuler
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialog.getBackup" max-width="30%">
			<v-card>
				<v-card-title class="headline">
					Sauvegarde terminée !
				</v-card-title>
				<v-card-text>
					<p>Le fichier à bien été généré et téléchargé !</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="dialog.getBackup = false">
						OK !
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
	const { saveAs } = require('file-saver');

	import Head from '@/components/graphics/head';

	import InfoCustomer from '@/components/modules/@customer/InfoCustomer';

	export default {
		props: {
			'id': {
				type: String
			}
		},
		components: {
			InfoCustomer,
			Head
		},
		name: 'AdminCustomer',
		data() {
			return {
				dialog: {
					getBackup: false,
					suspend: false,
					rgpd: false
				},

				companies: [],

				expanded: [],

				customer: this.$models.user,
				currentUser: {},

				userData: this.$models.user,

				headers: [{
						text: '',
						value: 'avatar'
					},
					{
						text: 'Entreprise',
						value: 'name'
					},
					{
						text: 'SIRET',
						value: 'siret'
					},
					{
						text: 'Position',
						value: 'rank'
					},
					{
						text: '',
						value: 'manage'
					},
				]
			}
		},
		computed: {
			formatDate() {
				return this.customer.birthdate ? this.$moment(this.customer.birthdate).format('DD/MM/YYYY') : '';
			}
		},
		watch: {
			customer: {
				deep: true,
				handler() {
					var tempDate = typeof this.customer.birthdate.toDate === "function" ? this.customer.birthdate.toDate() : this.customer.birthdate;

					if(this.customer.birthdate) this.customer.birthdate = this.$moment(tempDate).format("YYYY-MM-DD");
				}
			},
		},
		methods: {
			deleteAllData() {
				// Suppression des données profil et entreprises propriétaires, le reste doit rester untouch
			},
			makeABackup() {
				//var backup = {};

				var userBackup = {};

				// SAVE USER
				this.$db.collection("users").doc(this.id).get().then((doc) => {
					userBackup = {
						...doc.data(),
						id: doc.id
					};

					var blob = new Blob([JSON.stringify(userBackup)], {
						type: "text/plain;charset=utf-8"
					});

					saveAs(blob, `${this.id}_userProfile-${this.$moment().format("DD-MM-YYYY@HH-mm")}.json`);
				});

				/*this.$db.collection("companies").doc(this.id).collection(collection).get().then((ref) => {
					ref.forEach((doc) => {
						backup[doc.id] = {
							...doc.data(),
							id: doc.id
						};

						/*var subcollections = this.$functions.findWithAttr(this.modules, "collection", collection);

						if(subcollections) backup[doc.id].subcollections = {};

						this.modules[subcollections].subcollections.forEach((collection2) => {
							var subdocuments = [];

							this.$db.collection("companies").doc(this.id).collection(collection).doc(doc.id).collection(collection2).get().then((ref2) => {
								ref2.forEach((doc2) => {
									subdocuments.push({
										...doc2.data(),
										id: doc2.id
									});
								});

								backup[doc.id].subcollections[`_${collection2}`] = subdocuments;
							});
						});
					});

					/*console.log(1, backup)

					for (const record in backup) {
						console.log(2, record, backup[record].subcollections)
						
						for (const coll in backup[record].subcollections) {
							console.log(3, coll, JSON.stringify(coll));
						}
					}

					var blob = new Blob([JSON.stringify(backup)], {
						type: "text/plain;charset=utf-8"
					});

					saveAs(blob, `${this.id}_${collection}-${this.$moment().format("DD-MM-YYYY@HH-mm")}.json`);
				});*/

				this.dialog.getBackup = true;
			},

			resetPassword() {
				this.$firebase.auth().sendPasswordResetEmail(this.customer.email).then(() => {
					// Password reset email sent!
					// ..
				});
			},
			updateProfile() {
				/*this.$db.collection('users').doc(this.id).set({
					'civility': this.userInfo.civility,
					'firstName': this.userInfo.firstName,
					'lastName': this.userInfo.lastName,

					'birthdate': this.userInfo.birthdate,
					'creationDate': new Date(),

					'website': this.userInfo.website,
					'avatar': this.userInfo.avatar,
					'bio': this.userInfo.bio,

					'address': {
						'street': this.userInfo.address.street,
						'city': this.userInfo.address.city,
						'region': this.userInfo.address.region,
						'country': this.userInfo.address.country,
						'postalCode': this.userInfo.address.postalCode
					},

					'phone': this.userInfo.phone,
					'email': this.userInfo.email,

					'job': this.userInfo.job,

					'tva': {
						'status': this.userInfo.tva.status,
						'number': this.userInfo.tva.number
					},

					'bank_info': {
						'country': this.userInfo.bank_info.country,
						'bank': this.userInfo.bank_info.bank,
						'iban': this.userInfo.bank_info.iban
					},

					'contact': {
						'call': this.userInfo.contact.call,
						'mail': this.userInfo.contact.mail,
						'sms': this.userInfo.contact.sms,
						'preference': this.userInfo.contact.preference
					},

					'notification': {
						'sync': {
							'mail': this.userInfo.notification.sync.mail,
							'app': this.userInfo.notification.sync.app
						},
						'quote': {
							'mail': this.userInfo.notification.quote.mail,
							'app': this.userInfo.notification.quote.app
						}
					}
				}, {
					merge: true
				}).then(() => {
					this.$firebase.auth().currentUser.updateProfile({
						photoURL: this.userInfo.avatar
					}).then(() => {
						this.$store.dispatch('setSnackbar', {
							text: "Votre compte à bien été mis à jour.",
						});
					}).catch((err) => {
						this.$store.dispatch('setSnackbar', {
							text: err,
							timeout: 6000,
							x: 'right',
							y: 'top',
						});
					});
				}).catch((err) => {
					this.$store.dispatch('setSnackbar', {
						text: err,
						color: "error"
					});
				});*/
			},

			init() {
				this.$db.collection("users").doc(this.id).onSnapshot(doc => {
					this.customer = {
						...doc.data(),
						creationDate: this.$moment(),
						id: doc.id
					};

					this.currentUser = this.$firebase.auth().currentUser;

					document.title = `${this.customer.firstName} - Utilisateur n°${this.customer.nClient}`;

					this.companies = [];

					this.$functions.queryDatabase(`users/${this.userData.id}/companies`).then((res) => {
						res.forEach((doc) => {
							this.$db.collection('companies').doc(doc.id).get().then(doc2 => {
								this.companies.push({
									...this.$models.company,
									...doc2.data(),
									...doc.data(),
									id: doc2.id
								});
							});
						})
					});
				});
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot((doc) => {
				this.userData = {
					...doc.data(),
					id: doc.id
				};

				this.init();
			});
		}
	}
</script>